import Glide from '@glidejs/glide'
import yssHorizontalMLB from '../../renderers/yss_horizontal_mlb'
import yssVerticalMLB from '../../renderers/yss_vertical_mlb'
import { rapidReady, ylkStringToObj } from '../../../../lib/utils/rapid'
import { Constants, errorLogger } from '../../../../lib/ads/utils'

const SCORES_TITLE = '.scores-title'
const SCORES_MENU_SELECTED = '.scores-menu .selected'
const SCORE_SLIDE_DATA_SPORT = '.score-slide[data-sport]'
const GLIDE = '.glide'
const GLIDE_ARROW_LEFT = '.glide__arrow--left'
const GLIDE_ARROW_RIGHT = '.glide__arrow--right'

const GLIDE_ARROW_LEFT_V2_H = '.m-yss__h .glide__arrow--left'
const GLIDE_ARROW_RIGHT_V2_H = '.m-yss__h .glide__arrow--right'
const SELECTED_SPORTS_V2_H = '.m-yss__h-pill.selected'
const SPORTS_PILLS_V2_H = 'm-yss__h-pill rapid-noclick-resp'
const SPORTS_TITLE_V2_H = '.m-yss__h-pill'

const VERTICAL_TYPE = 'vertical'
const HORIZONTAL_TYPE = 'horizontal'
const GAME_STATUS_IN_PROGRESS = 'IN_PROGRESS'
const GAME_STATUS_FINAL_CLASS_H = 'game-status-final'
const GAME_STATUS_FINAL_CLASS_V = 'm-yss__vs__game-status-final'
const GAME_STATUS_NOT_FINAL_CLASS = 'game-status-not-final'
const GAME_STATUS_NOT_FINAL = `.${GAME_STATUS_NOT_FINAL_CLASS}`

const SELECTED_OPTION_ID_V2 = 'm-yss__vs__list-id'
const SELECTED_OPTION_CLASS_V2 = 'm-yss__vs__dropdown_selected'
const SPORTS_LIST_V2 = '.m-yss__vs--main-list'

const SELECTED_OPTION_ID_V2H = 'm-yss_h_menu__list-id'
const SELECTED_OPTION_CLASS_V2H = 'm-yss_h_menu__dropdown_selected'
const SPORTS_LIST_V2H = '.m-yss__h .score-slide'

const LEAGUE_FALLBACK_ID_V2 = 'm-yss__vs_league__fallback'
const SEE_ALL_SCORE_ELEMENT_CLASS = '.m-yss__vs--footer'
const sportContainerElems = [
  ...document.querySelectorAll('.m-yss__vs'),
  ...document.querySelectorAll('.m-yss_h_menu'),
  ...document.querySelectorAll('.m-yss__h-scores-container')
]

const DELAY_IN_SECONDS = 90
const WEEK_DAY = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']

const IS_HORIZONTAL = !!document.querySelector('.m-yss__h')
const IS_VERTICAL = !!document.querySelector('.m-yss__vs')

const MLB_LEAGUE = 'mlb'
const NFL_LEAGUE = 'nfl'
const NBA_LEAGUE = 'nba'

const SLIDER_OPTION = {
  type: 'slider',
  hoverpause: false,
  bound: false,
  rewind: false,
  peak: 50,
  autoplay: false,
  perView: 8,
  gap: 0,
  startAt: 0,
  animationDuration: 300,
  perSwipe: '|',
  breakpoints: {
    1080: { perView: 5 },
    850: { perView: 4 },
    752: { perView: 6 },
    600: { perView: 4 },
    400: { perView: 3 },
    300: { perView: 2 },
    200: { perView: 1 }
  }
}

if (IS_HORIZONTAL) {
  SLIDER_OPTION.perView = 6
  SLIDER_OPTION.breakpoints = {
    1470: { perView: 5 },
    1050: { perView: 4 },
    748: { perView: 3 },
    400: { perView: 3 },
    300: { perView: 2 },
    200: { perView: 1 }
  }
  SLIDER_OPTION.CUSTOM_LEAGUE_NAME_WIDTH = 68
  SLIDER_OPTION.CUSTOM_LEAGUE_SCORE_WIDTH = 162
  SLIDER_OPTION.MWEB_CUSTOM_LEAGUE_SCORE_WIDTH = 144
  SLIDER_OPTION.SCORE_FALLBACK = 'score-fallback'
  SLIDER_OPTION.SPORT_TITLE_WRAP = 'sport-title-wrap'
  SLIDER_OPTION.LEAGUE_FALLBACK_CLASS = '.league--fallback'
}

const isHorizontalForSportPage = () => {
  const sportMenuElement = !!document.querySelector('.m-yss_h_menu.sports')
  if (sportMenuElement && window.innerWidth <= 600) {
    return true
  }
  return false
}

const isHorizontalForHomePage = () => {
  const sportMenuElement = !!document.querySelector('.m-yss_h_menu.homepage')
  if (sportMenuElement && window.innerWidth >= 601 && window.innerWidth <= 1123) {
    return true
  }
  return false
}

const setDefaultGradient = () => {
  const sportGradientElements = document.querySelectorAll('.score-wrap.first')
  if (sportGradientElements && sportGradientElements.length > 0) {
    sportGradientElements.forEach(slide => {
      if (slide.classList.contains('disableGradient')) {
        slide.classList.remove('disableGradient')
      }
      slide.classList.add('enableGradient')
    })
  }
}

const setRightArrowGradient = count => {
  const sportGradientElements = document.querySelectorAll('.score-wrap.first')
  let countIndex = 0
  if (sportGradientElements && sportGradientElements.length > 0) {
    sportGradientElements.forEach(slide => {
      if (countIndex < count) {
        if (slide.classList.contains('enableGradient')) {
          slide.classList.remove('enableGradient')
        }
        slide.classList.add('disableGradient')
        countIndex += 1
      } else {
        if (slide.classList.contains('disableGradient')) {
          slide.classList.remove('disableGradient')
        }
        slide.classList.add('enableGradient')
      }
    })
  }
}

const debounceForSportUI = function(func, wait, immediate) {
  let timeout
  return function(...args) {
    const context = this
    const later = function() {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

const _sportsLogError = function(e, level = Constants.ERROR_LEVEL.ERROR) {
  const error = typeof e === 'string' ? new Error(e) : e
  const logger = errorLogger()
  logger(`[Yahoo sport scores ${level}] (controller) ${error?.message || 'Generic Error'}`, error)
}

const isVerticalGameStatusElement = gameId =>
  document.getElementById(`m_yss_vs_game_status_${gameId}`)

const isHorizontalGameStatusElement = gameId =>
  document.getElementById(`m_yss_h_game_status_${gameId}`)

const disabledGlideArrow = (elem, selector) => {
  const arrow = elem.querySelector(selector)
  if (arrow) {
    arrow.style.color = '#C7CDD2'
    arrow.style.cursor = 'none'
    arrow.style.display = 'block'
    if (IS_HORIZONTAL) {
      const arrow_path = arrow.querySelector('svg')
      arrow_path.classList.add('disable_arrow')
      arrow_path.classList.remove('enable_arrow')
    }
    arrow.disabled = true
  }
}

const enabledGlideArrow = (elem, selector) => {
  const arrow = elem.querySelector(selector)
  if (arrow) {
    arrow.style.color = '#0047ff'
    arrow.style.cursor = 'pointer'
    arrow.style.display = 'block'
    if (IS_HORIZONTAL) {
      const arrow_path = arrow.querySelector('svg')
      arrow_path.classList.add('enable_arrow')
      arrow_path.classList.remove('disable_arrow')
    }
    arrow.disabled = false
  }
}

const hideGlideArrow = (elem, selector) => {
  const arrow = elem.querySelector(selector)
  arrow.style.display = 'none'
}

const showGlideArrow = (elem, selector) => {
  const arrow = elem.querySelector(selector)
  arrow.style.display = 'block'
}

const _postData = async (url = '', data = {}) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  return response
}

const _formatAMPM = date => {
  let hours = new Date(date).getHours()
  let minutes = new Date(date).getMinutes()
  const AM_PM = hours >= 12 ? 'PM' : 'AM'
  hours %= 12
  hours = hours || 12
  minutes = minutes.toString().padStart(2, '0')
  return `${hours}:${minutes}${AM_PM}`
}

const isToUpdateBasedOnTimeAndModuleView = startTime => {
  try {
    if (!sportContainerElems.length) {
      return false
    }
    if (window?.wafer?.utils !== undefined) {
      const { elementInView } = window.wafer.utils
      if (!elementInView) {
        return false
      }
      const sportModuleInView = sportContainerElems.find(elm =>
        elementInView(elm, {}, wafer.base.viewport)
      )
      if (sportModuleInView) {
        if (startTime) {
          const startTimeInMSec = new Date(startTime).getTime()
          const currTimeInMSec = Date.now()
          if (currTimeInMSec > startTimeInMSec) {
            return true
          }
        }
      }
    }
    return false
  } catch (err) {
    _sportsLogError(err)
    return false
  }
}

const setDateAndTimeForNonFinal = (gameId, startTime) => {
  const getDay = new Date(startTime).getDay()
  const getTime = _formatAMPM(startTime)
  const getDate = new Date(startTime).getDate()
  const currentDate = new Date().getDate()
  if (IS_HORIZONTAL && isHorizontalGameStatusElement(gameId)) {
    document.getElementById(`m_yss_h_game_status_${gameId}`).innerText =
      getDate === currentDate ? getTime : `${WEEK_DAY[getDay]} ${getTime}`
  }
  if (IS_VERTICAL && isVerticalGameStatusElement(gameId)) {
    document.getElementById(`m_yss_vs_game_status_${gameId}`).innerText =
      getDate === currentDate ? getTime : `${WEEK_DAY[getDay]} ${getTime}`
  }
}

const updateMLBSport = (gameId, renderObj, displayName, out, type) => {
  let markup = ''
  if (type === VERTICAL_TYPE && isVerticalGameStatusElement(gameId)) {
    markup = yssVerticalMLB(renderObj)
    document.getElementById(`m_yss_vs_game_status_${gameId}`).innerText = ''
    document.getElementById(`m_yss_vs_game_b_c_${gameId}`).innerHTML = ''
    document.getElementById(`m_yss_vs_game_b_c_${gameId}`).appendChild(markup)
  } else if (type === HORIZONTAL_TYPE && isHorizontalGameStatusElement(gameId)) {
    markup = yssHorizontalMLB(renderObj)
    document.getElementById(`m_yss_h_game_status_${gameId}`).innerText = `${displayName} - ${out}`
    document.getElementById(`m_yss_h_game_b_c_${gameId}`).innerHTML = ''
    document.getElementById(`m_yss_h_game_b_c_${gameId}`).appendChild(markup)
  }
}

const updateRealTimeGameUI = (gameId, displayName, type) => {
  const displayNameList = displayName.split(' ')
  if (type === VERTICAL_TYPE && isVerticalGameStatusElement(gameId)) {
    document.getElementById(`m_yss_vs_game_status_${gameId}`).innerText = `${displayNameList?.[1] ||
      ''}`
    document.getElementById(`m_yss_vs_game_b_c_${gameId}`).innerHTML = ''
    document.getElementById(`m_yss_vs_game_b_c_${gameId}`).innerText = `${displayNameList?.[0] ||
      ''}`
  } else if (type === HORIZONTAL_TYPE && isHorizontalGameStatusElement(gameId)) {
    document.getElementById(`m_yss_h_game_status_${gameId}`).innerText = `${displayNameList?.[1] ||
      ''} ${displayNameList?.[0]}`
    document.getElementById(`m_yss_h_game_b_c_${gameId}`).innerHTML = ''
  }
}

const updateNBASport = (gameId, displayName, type) => {
  updateRealTimeGameUI(gameId, displayName, type)
}

const updateNFLSport = (gameId, displayName, type) => {
  updateRealTimeGameUI(gameId, displayName, type)
}

const isToUpdateLeagueOrNot = gameId => {
  const excludedLeagues = [MLB_LEAGUE]
  for (let index = 0; index < excludedLeagues.length; index++) {
    if (gameId.startsWith(excludedLeagues[index])) {
      return true
    }
  }
  return false
}

const updateUIForNonFinal = sports_game => {
  const {
    awayTeam,
    homeTeam,
    status,
    gameId,
    displayName,
    out,
    firstBaseRunnerFlag,
    secondBaseRunnerFlag,
    thirdBaseRunnerFlag
  } = sports_game

  let onGoingGameFlag = false
  if (status === GAME_STATUS_IN_PROGRESS) {
    onGoingGameFlag = true
  }
  if (homeTeam?.score || homeTeam?.score === 0) {
    if (IS_HORIZONTAL) {
      document.getElementById(`m_yss_h_game_ht_sv_${gameId}`).innerText = homeTeam.score
    }
    if (IS_VERTICAL) {
      document.getElementById(`m_yss_vs_game_ht_sv_${gameId}`).innerText = homeTeam.score
    }
    onGoingGameFlag = true
  }
  if (awayTeam?.score || awayTeam?.score === 0) {
    if (IS_HORIZONTAL) {
      document.getElementById(`m_yss_h_game_at_sv_${gameId}`).innerText = awayTeam.score
    }
    if (IS_VERTICAL) {
      document.getElementById(`m_yss_vs_game_at_sv_${gameId}`).innerText = awayTeam.score
    }
    onGoingGameFlag = true
  }

  if (IS_HORIZONTAL && isHorizontalGameStatusElement(gameId)) {
    const horizontal_game_status =
      status.toLowerCase() !== 'final' ? GAME_STATUS_NOT_FINAL_CLASS : GAME_STATUS_FINAL_CLASS_H

    if (horizontal_game_status === GAME_STATUS_FINAL_CLASS_H) {
      document.getElementById(`m_yss_h_game_b_c_${gameId}`).innerHTML = ''
      document.getElementById(`m_yss_h_game_b_c_${gameId}`).innerText = ''
      document.getElementById(`m_yss_h_game_status_${gameId}`).className = GAME_STATUS_FINAL_CLASS_H
      document.getElementById(`m_yss_h_game_status_${gameId}`).innerText = displayName
      if (awayTeam?.winner) {
        document.getElementById(
          `m_yss_h_game_at_${gameId}`
        ).className = `away-team ${awayTeam.winner}`
      } else {
        document.getElementById(`m_yss_h_game_at_${gameId}`).className = `away-team`
      }
      if (homeTeam?.winner) {
        document.getElementById(
          `m_yss_h_game_ht_${gameId}`
        ).className = `home-team ${homeTeam.winner}`
      } else {
        document.getElementById(`m_yss_h_game_ht_${gameId}`).className = `home-team`
      }
    } else if (onGoingGameFlag) {
      document.getElementById(
        `m_yss_h_game_at_${gameId}`
      ).className = `away-team ${GAME_STATUS_FINAL_CLASS_H} inprogress`
      document.getElementById(
        `m_yss_h_game_ht_${gameId}`
      ).className = `home-team ${GAME_STATUS_FINAL_CLASS_H} inprogress`

      if (gameId.startsWith(MLB_LEAGUE)) {
        const renderObj = {
          firstBaseRunnerFlag,
          secondBaseRunnerFlag,
          thirdBaseRunnerFlag,
          displayName,
          out
        }
        updateMLBSport(gameId, renderObj, displayName, out, HORIZONTAL_TYPE)
      } else if (gameId.startsWith(NBA_LEAGUE)) {
        updateNBASport(gameId, displayName, HORIZONTAL_TYPE)
      } else if (gameId.startsWith(NFL_LEAGUE)) {
        updateNFLSport(gameId, displayName, HORIZONTAL_TYPE)
      }
    }
  }
  if (IS_VERTICAL && isVerticalGameStatusElement(gameId)) {
    const vertical_game_status =
      status.toLowerCase() !== 'final' ? GAME_STATUS_NOT_FINAL_CLASS : GAME_STATUS_FINAL_CLASS_V

    if (vertical_game_status === GAME_STATUS_FINAL_CLASS_V) {
      document.getElementById(`m_yss_vs_game_b_c_${gameId}`).innerHTML = ''
      document.getElementById(`m_yss_vs_game_b_c_${gameId}`).innerText = ''
      document.getElementById(
        `m_yss_vs_game_status_${gameId}`
      ).className = GAME_STATUS_FINAL_CLASS_V
      document.getElementById(`m_yss_vs_game_status_${gameId}`).innerText = displayName
      if (awayTeam?.winner) {
        document.getElementById(
          `m_yss_vs_game_at_${gameId}`
        ).className = `m-yss__away-team ${awayTeam.winner}`
      } else {
        document.getElementById(`m_yss_vs_game_at_${gameId}`).className = `m-yss__away-team`
      }
      if (homeTeam?.winner) {
        document.getElementById(
          `m_yss_vs_game_ht_${gameId}`
        ).className = `m-yss__home-team ${homeTeam.winner}`
      } else {
        document.getElementById(`m_yss_vs_game_ht_${gameId}`).className = `m-yss__home-team`
      }
    } else if (onGoingGameFlag) {
      document.getElementById(
        `m_yss_vs_game_at_${gameId}`
      ).className = `m-yss__away-team ${GAME_STATUS_FINAL_CLASS_V} inprogress`
      document.getElementById(
        `m_yss_vs_game_ht_${gameId}`
      ).className = `m-yss__home-team ${GAME_STATUS_FINAL_CLASS_V} inprogress`

      document.getElementById(`m_yss_vs_game_status_${gameId}`).classList.add('inprogress')
      document.getElementById(`m_yss_vs_game_b_c_${gameId}`).classList.add('inprogress')

      if (gameId.startsWith(MLB_LEAGUE)) {
        const renderObj = {
          firstBaseRunnerFlag,
          secondBaseRunnerFlag,
          thirdBaseRunnerFlag,
          displayName,
          out
        }
        updateMLBSport(gameId, renderObj, displayName, out, VERTICAL_TYPE)
      } else if (gameId.startsWith(NBA_LEAGUE)) {
        updateNBASport(gameId, displayName, VERTICAL_TYPE)
      } else if (gameId.startsWith(NFL_LEAGUE)) {
        updateNFLSport(gameId, displayName, VERTICAL_TYPE)
      }
    }
  }
}

const DateAndTimeController = flag => {
  const GameStatusElements = document.querySelectorAll(GAME_STATUS_NOT_FINAL)
  const gameIds = []
  GameStatusElements.forEach(game => {
    const {
      dataset: { gameId, startTime }
    } = game
    if (gameId && !gameIds.includes(gameId)) {
      if (!isToUpdateLeagueOrNot(gameId) && isToUpdateBasedOnTimeAndModuleView(startTime)) {
        gameIds.push(gameId)
      }
      if (!flag && startTime) setDateAndTimeForNonFinal(gameId, startTime)
    }
  })

  if (gameIds.length > 0) {
    const url = `/nex/modules/yahoo_sports_scores/v1/?isXHR=true&m_mode=json`
    _postData(url, {
      data: gameIds
    })
      .then(response => response.json())
      .then(responseData => {
        const {
          data: { response }
        } = responseData
        response.forEach(sports_game => {
          updateUIForNonFinal(sports_game)
        })
      })
      .catch(err => {
        _sportsLogError(err)
      })
      .finally(() => {
        setTimeout(DateAndTimeController, DELAY_IN_SECONDS * 1000, true)
      })
  } else {
    setTimeout(DateAndTimeController, DELAY_IN_SECONDS * 1000, true)
  }
}

const customLeagueFallbackWidth = () => {
  const default_width = SLIDER_OPTION.CUSTOM_LEAGUE_NAME_WIDTH + 430
  const glideTrackElement = document.querySelector('.m-yss__h-scores-container .glide__track')
  const glideTrackWidth = glideTrackElement ? glideTrackElement.clientWidth : default_width
  SLIDER_OPTION.CUSTOM_LEAGUE_FALLBACK_WIDTH =
    (glideTrackWidth > default_width ? glideTrackWidth : default_width) -
    SLIDER_OPTION.CUSTOM_LEAGUE_NAME_WIDTH
}

export default class YahooSportsScores {
  constructor({ selector }) {
    DateAndTimeController()
    this._addEventListeners()
    this.elems = [...document.querySelectorAll(selector)]
    if (!this.elems.length) {
      return
    }
    this.elems.forEach(elem => this.init(elem))
  }

  _addEventListeners() {
    document.addEventListener('click', evt => {
      this.onClickEvent(evt)
    })
    if (IS_VERTICAL) {
      this.applyVerticalDefaultFilters()
    }
    if (IS_HORIZONTAL) {
      this.applyHorizontalDefaultFilters()
    }
  }

  applyHorizontalDefaultFilters() {
    const currentlySelectedLabel = document.getElementById(SELECTED_OPTION_ID_V2H)
    if (
      (isHorizontalForSportPage() || isHorizontalForHomePage()) &&
      currentlySelectedLabel &&
      currentlySelectedLabel?.dataset?.value
    ) {
      this.applyFilters(currentlySelectedLabel?.dataset?.value)
    }
  }

  applyVerticalDefaultFilters() {
    const currentlySelectedLabel = document.getElementById(SELECTED_OPTION_ID_V2)
    if (currentlySelectedLabel && currentlySelectedLabel?.dataset?.value) {
      this.applyFilters(currentlySelectedLabel?.dataset?.value)
    }
  }

  clickOnSportTab(value) {
    const currTab = document.querySelector(SELECTED_SPORTS_V2_H)
    const targetTab = document.querySelector(`${SPORTS_TITLE_V2_H}[data-sport='${value}']`)
    if (currTab && targetTab && currTab !== targetTab) {
      currTab.className = SPORTS_PILLS_V2_H
      targetTab.className = `${SPORTS_PILLS_V2_H} selected`
      const element = document.querySelector(`.m-yahoo-sports-scores.m-yss__h--container.cell`)
      if (element) {
        this.sportsWrapToggle_V2_H(element, targetTab)
      }
    }
  }

  applyFilters(defaultValue) {
    const vertical_selected_id = document.getElementById(SELECTED_OPTION_ID_V2)
    const horizontal_selected_id = document.getElementById(SELECTED_OPTION_ID_V2H)
    if (vertical_selected_id) {
      vertical_selected_id.innerText = defaultValue.toUpperCase()
      vertical_selected_id.setAttribute('data-value', defaultValue)
    }
    if (horizontal_selected_id) {
      horizontal_selected_id.innerText = `Scores: ${defaultValue.toUpperCase()}`
      horizontal_selected_id.setAttribute('data-value', defaultValue)
    }
    if (IS_VERTICAL) {
      let nodeList = document.querySelectorAll(SPORTS_LIST_V2)
      const leagueFallback = document.getElementById(LEAGUE_FALLBACK_ID_V2)
      const seeAllElement = document.querySelector(SEE_ALL_SCORE_ELEMENT_CLASS)

      if (leagueFallback) {
        leagueFallback.classList.remove('league_active')
      }
      if (seeAllElement) {
        seeAllElement.classList.remove('invisible')
      }
      nodeList = [...nodeList]

      let listIndex = 0
      let fallback = true
      nodeList.forEach(item => {
        const {
          dataset: { gameId }
        } = item
        if (gameId) {
          item.classList.add('inVisible')
          if (gameId.toLowerCase().startsWith(defaultValue.toLowerCase())) {
            listIndex += 1
            if (listIndex < 5) {
              item.classList.remove('inVisible')
              fallback = false
            }
          }
        }
      })
      if (fallback && leagueFallback) {
        leagueFallback.classList.add('league_active')
        if (seeAllElement) {
          seeAllElement.classList.add('invisible')
        }
      }
    }

    if ((isHorizontalForSportPage() || isHorizontalForHomePage()) && IS_HORIZONTAL) {
      let nodeListH = document.querySelectorAll(SPORTS_LIST_V2H)
      nodeListH = [...nodeListH]

      nodeListH.forEach(item => {
        const {
          dataset: { sport }
        } = item
        if (sport) {
          item.classList.add('inVisible')
          if (sport.toLowerCase().startsWith(defaultValue.toLowerCase())) {
            if (item.classList.contains(`m-yss__h_league_type_${defaultValue?.toLowerCase()}`)) {
              item.classList.remove('inVisible')
            }
          }

          if (isHorizontalForHomePage()) {
            item.classList.remove('inVisible')
          }
        }
      })
    }
  }

  showMoreOptions(items, selected_id) {
    if (items[0].classList.contains('visible')) {
      this.closeFilters(items)
    } else {
      this.closeFilters(items)
      items[0].classList.add('visible')
      items[0].parentNode.setAttribute('aria-expanded', 'true')
      items[0].parentNode.style.zIndex = '100'
      if (items[0].parentNode.previousSibling.classList.contains('selected_tag')) {
        items[0].parentNode.previousSibling.classList.add('open')
      }
      const itemsChildNodes = items[0].childNodes
      const defaultLabel = document.getElementById(selected_id)?.dataset?.value

      items._activeTab = defaultLabel
      this.rapidBeacon(items, document.getElementById(selected_id), {
        slk: defaultLabel,
        subsec: defaultLabel
      })

      itemsChildNodes.forEach(child => {
        if (child.dataset.value.toLowerCase() === defaultLabel.toLowerCase()) {
          child.childNodes[0].classList.add('selected')
        } else {
          child.classList.remove('inVisible')
          child.childNodes[0].classList.remove('selected')
        }
      })
    }
  }

  closeFilters(items) {
    items[0].classList.remove('visible')
    items[0].parentNode.setAttribute('aria-expanded', 'false')
    if (items[0].parentNode.previousSibling.classList.contains('selected_tag')) {
      items[0].parentNode.previousSibling.classList.remove('open')
    }
  }

  onClickEvent(evt) {
    if (evt.target.classList.contains(SELECTED_OPTION_CLASS_V2)) {
      if (this.eList && !this.eList[0].classList.contains(evt.target.id)) {
        this.closeFilters(this.eList)
      }
      this.eList = document.getElementsByClassName(evt.target.id)
      this.showMoreOptions(this.eList, SELECTED_OPTION_ID_V2)
    } else if (
      evt.target.classList.contains(SELECTED_OPTION_CLASS_V2H) &&
      (isHorizontalForSportPage() || isHorizontalForHomePage())
    ) {
      if (this.eList && !this.eList[0].classList.contains(evt.target.id)) {
        this.closeFilters(this.eList)
      }
      this.eList = document.getElementsByClassName(evt.target.id)
      this.showMoreOptions(this.eList, SELECTED_OPTION_ID_V2H)
    } else if (this.eList && evt.target.nodeName !== 'LABEL') {
      this.closeFilters(this.eList)
    } else if (
      this.eList &&
      evt.target.nodeName === 'LABEL' &&
      (evt.target.classList.contains('m-yss__vs__dropdown--list_label') ||
        evt.target.classList.contains('m-yss_h_menu__dropdown--list_label')) &&
      !evt.target.classList.contains('disabled')
    ) {
      const { value } = evt.target.dataset
      this.closeFilters(this.eList)
      if (
        evt.target.classList.contains('m-yss__vs__dropdown--list_label') ||
        evt.target.classList.contains('m-yss_h_menu__dropdown--list_label')
      ) {
        evt._activeTab = value
        this.rapidBeacon(evt, evt.target)
      }
      if (value) {
        this.clickOnSportTab(value)
      }
      if (evt.target.classList.contains('m-yss__vs__dropdown--list_label')) {
        this.applyFilters(value)
      }
    }
  }

  init(elem) {
    this.scoresMenuToggle(elem)
    this.sportsMenuToggle_V2_H(elem)
    this.initialiseSlider(elem, SLIDER_OPTION)
  }

  scoresMenuToggle(elem) {
    const tabs = elem.querySelectorAll(SCORES_TITLE)
    tabs.forEach(tab =>
      tab.addEventListener('click', ({ target }) => {
        if (!target.parentElement.classList.contains('selected')) {
          this.scoresMenuClickHandler(elem, target)
          this.scoresWrapToggle(elem, target)
          this.glide.go('<<')
        }
      })
    )
  }

  scoresMenuClickHandler(elem, target) {
    const currTab = elem.querySelector(SCORES_MENU_SELECTED)
    currTab.className = ''
    const newTab = target.parentElement
    newTab.className = 'selected'
    const { sport } = newTab.dataset
    elem._activeTab = sport
    this.rapidBeacon(elem, newTab)
  }

  scoresWrapToggle(elem, target) {
    const selectedTab = target.parentElement.dataset.sport
    const scoresSlides = elem.querySelectorAll(SCORE_SLIDE_DATA_SPORT)

    scoresSlides.forEach(scoresSlide => {
      if (selectedTab !== 'all') {
        if (scoresSlide.dataset.sport !== selectedTab) {
          scoresSlide.style.display = 'none'
        } else {
          scoresSlide.style.display = 'table'
        }
      } else {
        scoresSlide.style.display = 'table'
      }
    })
  }

  resizeEventHandler() {
    this.syncSportsUI()
    if (IS_HORIZONTAL && !isHorizontalForSportPage()) {
      SLIDER_OPTION.mWeb = false
      this.glide.mount({ setCustomSlideWidth: this.setCustomSlideWidth })
      if (SLIDER_OPTION.customScrollIndex && SLIDER_OPTION.customTranslateWidth) {
        this.sliderTranslateWidth(SLIDER_OPTION.customScrollIndex)
      } else {
        this.sliderTranslateWidth(0)
      }
    } else if (IS_HORIZONTAL && isHorizontalForSportPage()) {
      if (!SLIDER_OPTION.mWeb) {
        this.sliderTranslateWidth(0)
        SLIDER_OPTION.mWeb = true
      }
    }
  }

  syncSportsUI() {
    if (IS_HORIZONTAL) {
      let nodeListH = document.querySelectorAll(SPORTS_LIST_V2H)
      nodeListH = [...nodeListH]
      nodeListH.forEach(item => {
        const {
          dataset: { sport }
        } = item
        if (sport) {
          item.classList.remove('inVisible')
        }
      })
      if (isHorizontalForSportPage() || isHorizontalForHomePage()) {
        this.applyHorizontalDefaultFilters()
      }
    }
  }

  setCustomSlideWidth(_Glide, Components, Events) {
    return {
      mount() {
        if (IS_HORIZONTAL) {
          customLeagueFallbackWidth()
          const { slides } = Components.Html
          slides.forEach((slide, index) => {
            if (slide.classList.contains(SLIDER_OPTION.SCORE_FALLBACK)) {
              slide.style.width = `${SLIDER_OPTION.CUSTOM_LEAGUE_FALLBACK_WIDTH}px`
              slide.style['min-width'] = `${SLIDER_OPTION.CUSTOM_LEAGUE_FALLBACK_WIDTH}px`

              const Leagues = document.querySelectorAll(SLIDER_OPTION.LEAGUE_FALLBACK_CLASS)
              if (Leagues && Leagues.length > 0) {
                Leagues.forEach(item => {
                  if (item.id !== LEAGUE_FALLBACK_ID_V2) {
                    item.style.width = `${SLIDER_OPTION.CUSTOM_LEAGUE_FALLBACK_WIDTH}px`
                    item.style['min-width'] = `${SLIDER_OPTION.CUSTOM_LEAGUE_FALLBACK_WIDTH}px`
                  }
                })
              }
            } else if (slide.classList.contains(SLIDER_OPTION.SPORT_TITLE_WRAP)) {
              slide.style.width = `${SLIDER_OPTION.CUSTOM_LEAGUE_NAME_WIDTH}px`
              slide.style['min-width'] = `${SLIDER_OPTION.CUSTOM_LEAGUE_NAME_WIDTH}px`
            } else if (isHorizontalForSportPage()) {
              slide.style.width = `${SLIDER_OPTION.MWEB_CUSTOM_LEAGUE_SCORE_WIDTH}px`
              slide.style['min-width'] = `${SLIDER_OPTION.MWEB_CUSTOM_LEAGUE_SCORE_WIDTH}px`
            } else {
              slide.style.width = `${SLIDER_OPTION.CUSTOM_LEAGUE_SCORE_WIDTH}px`
              slide.style['min-width'] = `${SLIDER_OPTION.CUSTOM_LEAGUE_SCORE_WIDTH}px`
            }
          })
        }
      }
    }
  }

  sliderTranslateWidth(nextIndex) {
    if (nextIndex === 0) {
      SLIDER_OPTION.customMWebScrollIndex = 0
      this.glide.go('<<')
      this.setSliderZeroWidthToUI()
      return
    }

    if (isHorizontalForSportPage()) {
      const mTranslateWidth = nextIndex * SLIDER_OPTION.MWEB_CUSTOM_LEAGUE_SCORE_WIDTH
      SLIDER_OPTION.mWeb = true
      this.setSliderWidthToUI(mTranslateWidth)
      setDefaultGradient(0)
      return
    }
    const { actualTranslateWidth, actualIndex, count: titleCount } = this.getSliderWidth(nextIndex)
    SLIDER_OPTION.customScrollIndex = actualIndex
    SLIDER_OPTION.customTranslateWidth = actualTranslateWidth
    setRightArrowGradient(titleCount)
    this.glide.go(`=${SLIDER_OPTION.customScrollIndex}`)
    this.setSliderWidthToUI(SLIDER_OPTION.customTranslateWidth)
  }

  getSliderWidth(nextIndex) {
    const scoresSlides = document.querySelectorAll('.score-slide')
    let actualIndex = nextIndex
    let count = 0
    let fbCount = 0
    for (let index = 0; index < nextIndex; index++) {
      if (scoresSlides?.[index]) {
        count = scoresSlides[index]?.classList.contains(SLIDER_OPTION.SPORT_TITLE_WRAP)
          ? count + 1
          : count
        fbCount = scoresSlides[index]?.classList.contains(SLIDER_OPTION.SCORE_FALLBACK)
          ? fbCount + 1
          : fbCount
      } else {
        actualIndex = index - 1
        break
      }
    }

    const actualTranslateWidth =
      (actualIndex - count - fbCount) * SLIDER_OPTION.CUSTOM_LEAGUE_SCORE_WIDTH +
      count * SLIDER_OPTION.CUSTOM_LEAGUE_NAME_WIDTH +
      fbCount * SLIDER_OPTION.CUSTOM_LEAGUE_FALLBACK_WIDTH
    return { actualTranslateWidth, actualIndex, count }
  }

  setSliderWidthToUI(width) {
    document.querySelector('.scores-wrap').style.transform = `translate3d(-${width}px, 0px, 0px)`
  }

  setSliderZeroWidthToUI() {
    document.querySelector('.scores-wrap').style.transform = `translate3d(0px, 0px, 0px)`
    setDefaultGradient(0)
  }

  updateCustomSlide(nextIndex, scoresSlides) {
    if (IS_HORIZONTAL) {
      const currTab = document.querySelector(SELECTED_SPORTS_V2_H)
      this.sliderTranslateWidth(nextIndex)
      if (!isHorizontalForSportPage()) {
        this.onScrollChangeHighlightedTab(currTab, scoresSlides?.[nextIndex])
      }
    }
  }

  glideConfig() {
    return new Promise((resolve, reject) => {
      const currentIndex = this.glide.index
      const { perView } = this.glide.settings
      resolve({ perView, currentIndex })
    })
  }

  moveSliderRight() {
    if (IS_HORIZONTAL) {
      this.glideConfig().then(response => {
        const { perView, currentIndex } = response
        let scoresSlides = document.querySelectorAll('.score-slide')
        scoresSlides = [...scoresSlides]
        let nextIndex = currentIndex + perView
        if (isHorizontalForSportPage()) {
          const currTab = document.querySelector(SELECTED_SPORTS_V2_H)
          if (currTab) {
            const { sport } = currTab.dataset
            const filterSlides = scoresSlides.filter(item =>
              item.classList.contains(`m-yss__h_league_type_${sport?.toLowerCase()}`)
            )
            if (nextIndex >= filterSlides.length) {
              nextIndex = filterSlides.length - 2
            }
            SLIDER_OPTION.customMWebScrollIndex = nextIndex
          }
        } else if (currentIndex >= scoresSlides.length) {
          nextIndex = currentIndex
        } else if (nextIndex >= scoresSlides.length) {
          nextIndex = currentIndex + 1
        }
        this.updateCustomSlide(nextIndex, scoresSlides)
      })
    }
  }

  moveSliderLeft() {
    if (IS_HORIZONTAL) {
      this.glideConfig().then(response => {
        const { perView, currentIndex } = response
        let scoresSlides = document.querySelectorAll('.score-slide')
        scoresSlides = [...scoresSlides]
        let nextIndex = currentIndex - perView
        nextIndex = nextIndex <= 0 ? 0 : nextIndex

        if (isHorizontalForSportPage()) {
          const currTab = document.querySelector(SELECTED_SPORTS_V2_H)
          if (currTab) {
            const { sport } = currTab.dataset
            const filterSlides = scoresSlides.filter(item =>
              item.classList.contains(`m-yss__h_league_type_${sport?.toLowerCase()}`)
            )
            if (nextIndex >= filterSlides.length) {
              nextIndex = filterSlides.length - 2
            }
            nextIndex -= perView
            if (SLIDER_OPTION.customMWebScrollIndex) {
              nextIndex = SLIDER_OPTION.customMWebScrollIndex - perView
              SLIDER_OPTION.customMWebScrollIndex = nextIndex <= 0 ? 0 : nextIndex
            }
            nextIndex = nextIndex <= 0 ? 0 : nextIndex
          }
        }
        nextIndex <= 0 ? this.glide.go('<<') : this.glide.go(`=${nextIndex + 1}`)
        this.updateCustomSlide(nextIndex, scoresSlides)
      })
    }
  }

  initialiseSlider(elem, config) {
    this.glide = new Glide(elem.querySelector(GLIDE), config)

    this.glide.on(['mount.after', 'run.start'], () => {
      hideGlideArrow(elem, GLIDE_ARROW_LEFT)
      disabledGlideArrow(elem, GLIDE_ARROW_LEFT_V2_H)
    })
    this.glide.on('run.end', () => {
      hideGlideArrow(elem, GLIDE_ARROW_RIGHT)
      disabledGlideArrow(elem, GLIDE_ARROW_RIGHT_V2_H)
    })
    this.glide.on('run.before', evt => {
      if (IS_HORIZONTAL) {
        const { direction, steps } = evt
        if (direction === '|') {
          if (steps === '<') {
            this.moveSliderLeft()
          } else if (steps === '>') {
            this.moveSliderRight()
          }
        }
      }
      showGlideArrow(elem, GLIDE_ARROW_LEFT)
      showGlideArrow(elem, GLIDE_ARROW_RIGHT)
      enabledGlideArrow(elem, GLIDE_ARROW_LEFT_V2_H)
      enabledGlideArrow(elem, GLIDE_ARROW_RIGHT_V2_H)
    })
    const rapidMod = elem.closest('[data-rapid=true]')
    this.glide.on(['run.after'], evt => {
      rapidReady(rapid => {
        rapid.refreshModule(rapidMod.id)
      })
    })
    this.glide.on(
      'resize',
      debounceForSportUI(e => {
        this.resizeEventHandler()
      }, 200)
    )

    const leftArrow = elem.querySelector(GLIDE_ARROW_LEFT)
    if (leftArrow) {
      leftArrow.addEventListener('click', () => {
        this.rapidBeacon(elem, leftArrow)
        this.moveSliderLeft()
      })
    }

    const rightArrow = elem.querySelector(GLIDE_ARROW_RIGHT)
    if (rightArrow) {
      rightArrow.addEventListener('click', () => {
        this.rapidBeacon(elem, rightArrow)
        this.moveSliderRight()
      })
    }

    const scoreCards = [...elem.querySelectorAll('.score')]
    scoreCards.forEach(scoreCard => {
      scoreCard.addEventListener('click', () => {
        this.rapidBeacon(elem, scoreCard)
      })
    })

    this.glide.mount({ setCustomSlideWidth: this.setCustomSlideWidth })
  }

  onScrollChangeHighlightedTab(currTab, targetLeague) {
    if (currTab && targetLeague) {
      const { sport: league } = targetLeague.dataset
      const { sport: oldLeague } = currTab.dataset
      if (league && oldLeague && league.toLowerCase() !== oldLeague.toLowerCase()) {
        if (currTab) {
          currTab.className = SPORTS_PILLS_V2_H
        }
        const newTab = document.querySelector(
          `${SPORTS_TITLE_V2_H}[data-sport=${league.toLowerCase()}]`
        )
        newTab.className = `${SPORTS_PILLS_V2_H} selected`
        this.applyFilters(league?.toLowerCase())
      }
    }
  }

  rapidBeacon(elem, target, overridesYlk = {}) {
    const tab = elem._activeTab || 'all'
    const { ylk } = target.dataset
    let ylkObj = ylkStringToObj(ylk)
    ylkObj.subsec = tab
    ylkObj = { ...ylkObj, ...overridesYlk }
    rapidReady(rapid => {
      rapid.beaconClick(ylkObj.sec, ylkObj.slk, ylkObj.itc, ylkObj)
    })
  }

  sportsMenuToggle_V2_H(elem) {
    const tabs = elem.querySelectorAll(SPORTS_TITLE_V2_H)
    tabs.forEach(tab =>
      tab.addEventListener('click', ({ target }) => {
        if (!target.classList.contains('disabled')) {
          this.sportsMenuClickHandler_V2_H(elem, target)
          this.sportsWrapToggle_V2_H(elem, target)
        }
      })
    )
  }

  sportsMenuClickHandler_V2_H(elem, target) {
    const currTab = elem.querySelector(SELECTED_SPORTS_V2_H)
    if (currTab) {
      currTab.className = SPORTS_PILLS_V2_H
    }
    const newTab = target
    newTab.className = `${SPORTS_PILLS_V2_H} selected`
    const { sport } = newTab.dataset
    elem._activeTab = sport
    this.rapidBeacon(elem, newTab)
  }

  sportsWrapToggle_V2_H(currElem, target) {
    const selectedPill = target.dataset.sport?.toLowerCase()
    const scoresSlides = currElem.querySelectorAll(SCORE_SLIDE_DATA_SPORT)
    let scrollIndex = 0
    for (let index = 0; index < scoresSlides.length; index++) {
      if (scoresSlides[index].dataset.sport.toLowerCase() === selectedPill) {
        scrollIndex = index
        break
      }
    }
    this.syncSportsUI()
    this.applyFilters(selectedPill)
    if (isHorizontalForSportPage()) {
      SLIDER_OPTION.customScrollIndex = scrollIndex
      SLIDER_OPTION.customTranslateWidth = this.getSliderWidth(scrollIndex)
      SLIDER_OPTION.customMWebScrollIndex = 0
      this.glide.go('<<')
      this.sliderTranslateWidth(0)
      return
    }
    if (scrollIndex) {
      this.sliderTranslateWidth(scrollIndex)
      this.elems.forEach(elem => {
        showGlideArrow(elem, GLIDE_ARROW_LEFT)
        showGlideArrow(elem, GLIDE_ARROW_RIGHT)
        enabledGlideArrow(elem, GLIDE_ARROW_LEFT_V2_H)
        enabledGlideArrow(elem, GLIDE_ARROW_RIGHT_V2_H)
      })
    } else {
      SLIDER_OPTION.customScrollIndex = 0
      SLIDER_OPTION.customTranslateWidth = 0
      this.sliderTranslateWidth(0)
    }
  }
}
